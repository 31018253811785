<template>
  <div class="add-device">
    <van-nav-bar
      title="添加设备"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <ul class="operation">
      <van-form ref="deviceForm">
        <li class="eCode">
          <van-field
            class="eCode-value"
            v-model="form.eCode"
            label="输入设备sn"
            name="eCode"
            :rules="[{ required: true, message: '' }]"
            placeholder="输入多参数设备sn号"
          />
        </li>
        <li>
          <van-field
            v-model="form.e2Code"
            rows="4"
            name="e2Code"
            autosize
            label="二维码地址"
            type="textarea"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入二维码地址"
          />
        </li>
        <li>
          <van-field
            style="width: 100%"
            readonly
            clickable
            name="dean_name"
            :value="dean_name"
            label="绑定机构"
            placeholder="请选择机构"
            @click="changePicker(1)"
            :rules="[{ required: true, message: '请选择机构' }]"
          />
          <van-popup position="bottom" v-model="showPicker">
            <van-search
              placeholder="请输入机构名称"
              v-model="deanName"
              @input="onSearch(deanName)"
            />
            <van-picker
              cancel-button-text="取消"
              value-key="dean_name"
              show-toolbar
              :columns="ret"
              @confirm="onConfirmPicker"
              @cancel="showPicker = false"
            />
          </van-popup>
        </li>
      </van-form>
    </ul>
    <van-button class="submit" @click="submit">提交</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ret: [],
      typeFlag: "",
      positionList: [],
      showPicker: false,
      deanName: "", //机构关键字查询
      dean_name: "",
      form: {
        eCode: "", //设备号
        e2Code: "", //二维码地址
        deanId: ""
      }
    };
  },
  mounted() {
    this.selectDeanInfo({});
  },
  methods: {
    // 查询机构列表
    selectDeanInfo(name) {
      this.$api.user
        .selectDeanInfo({ deanName: name })
        .then((res) => {
          this.ret = res.data;
          console.log(res);
        })
        .catch((err) => {
          this.$toast.fail(err.msg);
        });
    },
    changePicker(flag) {
      console.log(flag);
      this.typeFlag = flag;
      if (flag === 1) {
        this.deanName = "";
      }
      this.showPicker = true;
    },
    onSearch(name) {
      this.selectDeanInfo(name);
      // console.log(name);
    },
    // 新增设备提交绑定
    submit() {
      this.$refs.deviceForm
        .validate(["e2Code", "eCode"])
        .then(() => {
          let data = this.form;
          this.$api.user
            .addEquipment(data)
            .then((res) => {
              this.$toast.success(res.msg);
              this.$router.push({ path: "/device" });
            })
            .catch((err) => {
              this.$toast.fail(err.msg);
            });
        })
        .catch(() => {
          this.$toast.fail("请补充信息");
        });
    },
    // 确定选择的机构
    onConfirmPicker(val) {
      this.dean_name = val.dean_name;
      this.form.deanId = val.id;
      this.showPicker = false;
      // console.log(val);
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: "/device" });
    }
  }
};
</script>

<style lang="scss" scoped>
.add-device {
  position: relative;
  background: #f5f5f5;
  width: 7.5rem;
  height: 100vh;
  .operation {
    li {
      margin-top: 0.18rem;
    }
  }
  .van-nav-bar {
    ::v-deep .van-nav-bar__title {
      font-size: 0.36rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.5rem;
    }
  }
  .submit {
    border: 0;
    color: #ffffff;
    width: 5.9rem;
    height: 0.8rem;
    background: #2cce98;
    border-radius: 0.4rem;
    position: absolute;
    bottom: 0;
    left: 11%;
  }
}
</style>
