<template>
  <div class="my-tabs-cop">
    <van-tabbar
      v-model="active"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      safe-area-inset-bottom
      @change="openPage"
    >
      <van-tabbar-item
        v-for="item of list"
        :key="item.id"
        :class="item.classNameItem"
      >
        <span>{{ item.name }}</span>
        <template #icon="props">
          <img class="icon" :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  props: {
    activeColor: {
      type: String,
      default: () => "#2CCE98"
    },
    inactiveColor: {
      type: String,
      default: () => "#999999"
    },
    // propActive: {
    //   type: Number,
    //   default: () => 0
    // },
    list: {
      default: () => []
    }
  },
  data() {
    return {
      active: 0
    };
  },
  mounted() {
    // console.log(this.list);
  },
  methods: {
    openPage() {
      this.$emit("sendActive", this.active);
      // if (this.active == 0) this.$router.push("/");
      // else this.$router.push("/my");
    },

    // 设置当前激活的标签页
    setActiveInx(val) {
      this.active = val || 0;
    }
  }
};
</script>

<style lang="scss" scoped></style>
