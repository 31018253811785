<template>
  <div class="device-manage">
    <div class="fuzzy-query">
      <div class="title">医生管理</div>
      <div class="search">
        <van-field
          v-model="queryDeviceParam.phone"
          @keydown="onTextareaKeyDown"
          class="conditional-value"
          placeholder="请输入账号"
        />
        <van-button class="bt" @click="searchDoc">搜索</van-button>
      </div>
    </div>
    <div class="untreated-table">
      <div class="header">
        <span class="item">医生</span>
        <span class="item">账号</span>
        <span class="item">所属机构</span>
        <span class="item operate">操作</span>
      </div>
      <div class="table-container">
        <v-list
          @bottom-touch-notice="queryAllOrder"
          :loading="loading"
          :finished="finished"
        >
          <template #content>
            <ul v-for="item of deviceList" :key="item.id" class="table-data">
              <li class="item">{{ item.user_nickname }}</li>
              <li class="item">{{ item.user_account }}</li>
              <li class="item">{{ item.department_name }}</li>
              <li class="item operate" @click="delDoc(true, item)">删除账号</li>
            </ul>
          </template>
        </v-list>
      </div>
    </div>
    <van-popup v-model="showLogout" @close="closePopup" round>
      <div class="unbind-popup">
        <div class="title">警告提醒</div>
        <div class="content">是否确认删除该医生账号</div>
        <div class="foot">
          <div class="submit-notes" @click="userLogout">确认删除</div>
          <div class="close-notes" @click="closePopup">取消删除</div>
        </div>
      </div>
    </van-popup>
    <v-tabs
      :list="tabList"
      @sendActive="getActive"
      ref="tabs"
      class="v-tab"
    ></v-tabs>
  </div>
</template>

<script>
import VTabs from "@/components/VTabs.vue";
import VList from "@/components/v-list.vue";
export default {
  mounted() {
    this.setActiveInx();
    // this.getUserList({ pageNum: 1, pageSize: 7, userType: 3 });
  },
  data() {
    return {
      userForm: {},
      showLogout: false, //删除弹框状态
      queryDeviceParam: {
        pageNum: 1,
        pageSize: 7,
        phone: "" //设备号
      },
      deviceCode: "", //设备编码
      deviceList: [], //设备列表
      loading: false, //是否继续下拉
      finished: false,
      tabList: [
        {
          name: "设备管理",
          classNameItem: "item",
          classNameImgItem: "icon",
          active: require("@/components/image/tab-img/device/active.png"),
          inactive: require("@/components/image/tab-img/device/inactive.png")
        },
        {
          name: "用户管理",
          classNameItem: "item",
          classNameImgItem: "icon",
          active: require("@/components/image/tab-img/user/active.png"),
          inactive: require("@/components/image/tab-img/user/inactive.png")
        },
        {
          name: "医生管理",
          classNameItem: "item",
          classNameImgItem: "icon",
          active: require("@/components/image/tab-img/doctor/active.png"),
          inactive: require("@/components/image/tab-img/doctor/inactive.png")
        }
      ]
    };
  },
  methods: {
    setActiveInx() {
      this.$refs.tabs.setActiveInx(2);
    },
    // 关闭弹窗
    closePopup() {
      this.showLogout = false;
    },
    userLogout() {
      let data = {
        userId: this.userForm.id
      };
      this.$api.user
        .userLogout(data)
        .then((res) => {
          this.$toast.success(res.msg);
          this.showLogout = false;
          this.getUserList({ pageNum: 1, pageSize: 7, userType: 3 });
        })
        .catch((err) => {
          this.$toast.fail(err.msg);
        });
    },
    // 设备删除
    delDoc(status, row) {
      this.showLogout = status;
      this.userForm = row;
      // console.log(row);
    },
    getUserList(data) {
      this.$api.user
        .getUserList(data)
        .then((res) => {
          this.deviceList = res.data;
          // console.log(res);
        })
        .catch((err) => {
          this.deviceList = [];
          this.$toast.fail(err.msg);
        });
    },
    // 分页触底
    queryAllOrder() {
      this.loading = true;
      let data = this.queryDeviceParam;
      data.userType = 3;
      this.$api.user
        .getUserList(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          this.deviceList = this.deviceList.concat(res.data);
          if (this.deviceList.length >= res.total) {
            this.loading = true;
            this.finished = true;
          }
          this.queryDeviceParam.pageNum++;
        })
        .catch(() => {
          this.loading = true;
          this.finished = true;
        });
    },
    onTextareaKeyDown() {
      // if (event.keyCode == 13) {
      //   this.searchDoc();
      // }
    },
    // 搜索设备
    searchDoc() {
      this.loading = false;
      this.finished = false;
      this.deviceList = [];
      this.getUserList({
        pageNum: 1,
        phone: this.queryDeviceParam.phone,
        pageSize: 7,
        userType: 3
      });
    },

    // tab切换
    getActive(inx) {
      let urlList = {
        0: "/device",
        1: "/user",
        2: "/doctor"
      };

      if (inx == 2) return;

      this.$router.push({ path: urlList[inx] });
    }
  },
  components: { VTabs, VList }
};
</script>

<style lang="scss" scoped>
.device-manage {
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;

  .fuzzy-query {
    padding: 0.18rem 0.3rem;
    background-color: #fff;
    margin-bottom: 0.24rem;
    .title {
      width: 1.44rem;
      height: 0.5rem;
      font-size: 0.36rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.5rem;
      margin: 0.22rem auto;
    }

    .btn {
      width: 2.18rem;
      height: 0.62rem;
      background: #2cce98;
      border-radius: 0.08rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 0.16rem;
    }
    .type-selection {
      width: 6.9rem;
      height: 0.62rem;
      border-radius: 0.08rem;
      border: 0.02rem solid #2cce98;
      color: #2cce98;
      position: relative;
      margin-bottom: 0.16rem;

      &::after {
        content: " ";
        display: block;
        width: 0.28rem;
        height: 0.28rem;
        // background-image: url("./image/arrowhead.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
      }

      ::v-deep .van-dropdown-menu__bar {
        height: 100%;

        .van-dropdown-menu__item {
          .van-dropdown-menu__title {
            height: 100%;

            &::after {
              display: none;
            }

            .van-ellipsis {
              height: 100%;
              font-size: 0.28rem;
              font-weight: 400;
              line-height: 2;
            }
          }
        }
      }
    }

    .search {
      display: flex;
      justify-content: space-between;

      .conditional-value {
        padding: 0;
        background: #eeeeee;
        width: 4.6rem;

        ::v-deep .van-field__body {
          height: 0.6rem;

          .van-field__control {
            font-size: 0.32rem;
            height: 0.6rem;
            line-height: 0.6rem;
            padding-left: 0.48rem;
          }
        }

        ::v-deep .van-field__error-message {
          font-size: 0.23rem;
          padding-left: 0.48rem;
          margin-top: 0.1rem;
        }

        ::-webkit-input-placeholder {
          font-weight: 400;
          color: #999999;
          font-size: 0.32rem;
        }
      }

      .bt {
        width: 2.18rem;
        height: 0.62rem;
        background: #2cce98;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .untreated-table {
    .header {
      display: flex;
      justify-content: space-between;

      .item {
        flex: 1;
        height: 0.88rem;
        background: #2cce98;
        line-height: 0.88rem;
        text-align: center;
        font-size: 0.28rem;
        font-weight: 400;
        color: #ffffff;
        margin-right: 0.1rem;
      }

      .operate {
        // width: 2.18rem;
        margin: 0;
      }
    }

    .table-container {
      .v-list {
        height: 8.5rem;
        overflow-y: auto;
      }

      .table-data {
        display: flex;
        justify-content: space-between;
        margin-top: 0.16rem;

        .item {
          flex: 1;
          word-break: break-word;
          min-height: 0.88rem;
          padding: 0.04rem 0.3rem;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.1rem;
          text-align: center;
        }

        .operate,
        .look {
          // width: 2.18rem;
          text-align: center;
          font-size: 0.28rem;
          font-weight: bold;
          color: #ce2c2c;
        }

        .operate {
          margin: 0;
        }
      }
    }
  }
  .unbind-popup {
    width: 5.3rem;
    height: 3.5rem;
    background: #ffffff;
    border-radius: 0.2rem;
    .title {
      padding-top: 0.28rem;
      width: 1.28rem;
      height: 0.44rem;
      font-size: 0.32rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      margin: 0 auto;
      line-height: 0.44rem;
    }
    .content {
      padding: 0.72rem;
    }
    .foot {
      display: flex;
      justify-content: space-evenly;
      .submit-notes {
        font-weight: bold;
        color: #2cce98;
      }
    }
  }
}
</style>
