<template>
  <div class="login">
    <div class="name">账号登录</div>
    <ul class="operation">
      <van-form ref="loginForm">
        <li class="phone">
          <img class="img" src="./image/phone.png" alt="" />
          <van-field
            class="phone-value"
            v-model="phone"
            label="账号登录"
            name="phone"
            :rules="[{ required: true, message: '' }]"
            placeholder="请输入账号"
          />
        </li>
        <li class="code">
          <img class="img" src="./image/password.png" alt="" />
          <van-field
            v-model="password"
            name="password"
            label="密码登录"
            class="code-value"
            maxlength="6"
            type="password"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '' }]"
          />
          <!-- 这里按钮没有实际意义只是为了输入框下边的下划线，没有按钮情况下，没有下划线 -->
          <van-button class="occupying"></van-button>
        </li>
      </van-form>
    </ul>
    <van-button :disabled="isLogin" class="submit-login" @click="login"
      >登录</van-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
      codeBtnInfo: {
        countDown: 60,
        isDisabled: false,
        label: "获取验证码",
        timer: null
      },
      isLogin: false,
      loginType: 1 //1验证码登录 2账号密码登录
    };
  },
  mounted() {},
  destroyed() {},
  computed: {
    errMessage() {
      return "请填写您的密码";
    }
  },
  methods: {
    login() {
      this.$refs.loginForm
        .validate(["password", "phone"])
        .then(() => {
          let data = {
            username: this.phone,
            password: this.password
          };
          this.$api.login
            .login(data)
            .then((res) => {
              console.log(res);
              this.isLogin = true;
              let baseInfo = {
                // token: res.data.jwtToken,
                // accountType: res.data.userType,
                id: res.data.id
                // username: res.data.username
              };
              // 存储基本信息
              this.$store.dispatch("common/baseInfo", baseInfo);
              this.$router.push({ path: "/device" });
            })
            .catch((err) => {
              this.isLogin = false;
              this.$toast.fail(err.msg || "登录失败");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  background: #f6f6f6;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;
  position: relative;
  .name {
    width: 7.5rem;
    height: 0.88rem;
    background: #fff;
    text-align: center;
    font-size: 0.36rem;
    line-height: 0.88rem;
    .logo {
      width: 3.78rem;
      height: 0.98rem;
    }
  }

  // 操作
  .operation {
    margin-bottom: 0.24rem;

    .phone,
    .code {
      width: 7.5rem;
      height: 1.08rem;
      background: #ffffff;
      margin-top: 0.28rem;
      line-height: 1.08rem;
      position: relative;
      .code-value,
      .phone-value {
        ::v-deep .van-field__label {
          padding-left: 0.48rem;
          padding-top: 0.12rem;
        }
        ::v-deep .van-field__body {
          height: 0.8rem;
          .van-field__control {
            font-size: 0.32rem;
            height: 0.8rem;
            line-height: 0.8rem;
            padding-left: 0.48rem;
          }
        }
        ::v-deep .van-field__error-message {
          font-size: 0.23rem;
          padding-left: 0.48rem;
          // margin-top: 0.1rem;
        }
      }
      .img {
        width: 0.32rem;
        height: 0.32rem;
        position: absolute;
        left: 0.42rem;
        bottom: 0.38rem;
        z-index: 10;
      }
    }

    .code {
      .occupying {
        display: none;
      }
    }
  }

  // 登录按钮
  .submit-login {
    border: 0;
    color: #ffffff;
    width: 5.9rem;
    height: 0.8rem;
    background: #2cce98;
    border-radius: 0.4rem;
    position: absolute;
    top: 50%;
    &::before {
      display: none;
    }
  }
}
</style>
