<template>
  <div class="v-list">
    <van-list
      :loading="loading"
      :finished="finished"
      :finished-text="finishedText"
      :loading-text="loadingText"
      @load="onLoad"
    >
      <slot name="content" class="order-status"></slot>
    </van-list>
  </div>
</template>

<script>
export default {
  name: "v-list",
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    finishedText: {
      default: () => "没有更多了"
    },
    loadingText: {
      default: () => "加载中"
    },
    finished: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    onLoad() {
      this.$emit("bottom-touch-notice");
    }
  }
};
</script>

<style lang="scss" scoped></style>
